<template>
  <div class="nanfanregister">
    <bread-crumb :breadList="breadList"/>
    <div class="nanfanregister-content auto-center">
      <div class="nanfanregister-content-title">南繁备案情况</div>
      <el-table :data="tableData" style="width: 95%;">
        <el-table-column label width="10" align="left">
          <div
                  style="width:3px;height:50px;margin-left:-10px;background:rgba(51,128,254,1);border-radius:2px;"
          ></div>
        </el-table-column>
        <el-table-column width="100" label="备案属性" prop="registerProperty" align="center">
        </el-table-column>
        <el-table-column label="南繁方向" prop="nanfanDirection" align="center">
        </el-table-column>
        <el-table-column label="备案状态" prop="registerStatus" align="center">
        </el-table-column>
        <el-table-column label="备案年份" prop="year" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="createdDate" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="350">
          <template slot-scope="scope">
            <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-tickets"
                    @click="handleEdit(scope.$index, scope.row, 1)"
            >查看
            <!--  v-if="scope.row.registerStatus === 3 || scope.row.registerStatus ===4" -->
            </el-button>
            <!-- <el-button
                    size="mini"
                    type="danger"
                    @click="handleEdit(scope.$index, scope.row,2)"
                    icon="el-icon-delete"
            >删除
             v-if="scope.row.registerStatus === 5"
            </el-button> -->
            <el-button
                    size="mini"
                    v-if="scope.row.registerStatus === '已驳回'"
                    type="info"
                    icon="el-icon-edit"
                    @click="handleEdit(scope.$index, scope.row,3)"
            >编辑
            <!-- v-if="scope.row.registerStatus === 5" -->
            </el-button>
            <!-- <el-button
                    size="mini"
                    
                    type="text"
                    style="text-decoration:underline"
                    @click="handleEdit(scope.$index, scope.row,4)"
            >请填写备案信息
            v-if="scope.row.registerStatus === 2"
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- <page-cut :total="450" @pageChange="pageChange" :currentPage="1" :pageSize="5"></page-cut> -->
      <div class="nanfanregister-content-btn" style="margin-top:50px">
        <el-button type="primary" size="medium" @click="addRecord" icon="el-icon-plus">新增备案</el-button>
      </div>
      <el-dialog
              title="选择备案信息"
              center
              :visible.sync="showCover"
              :before-close="beforeClose"
              width="35%"
      >
        <div class="nanfanregister-content-item">
          <span>备案属性</span>
          <el-select v-model="type" placeholder="请选择" :disabled="disabled">
            <el-option
                    v-for="item in options_type"
                    :key="item.value"
                    :label="item.name"
                    :value="item"
                    :disabled="item.disabled"
                    v-show="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="nanfanregister-content-item">
          <span>南繁方向</span>
          <el-select v-model="direction" placeholder="请选择" :disabled="disabled_direction">
            <el-option
                    v-for="item in options_direction"
                    :key="item.value"
                    :label="item.name"
                    :value="item"
                    :disabled="item.disabled"
                    v-show="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="nanfanregister-content-item">
          <span>备案年份</span>
          <el-date-picker
                  v-model="datatime"
                  type="year"
                  value-format="yyyy"
                  placeholder="选择年">
          </el-date-picker>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
                  type="primary"
                  style="width:80%;"
                  @click="submit"
                  :disabled="!(type.value&&direction.value&&datatime)"
          >确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 审批 - 弹出框 -->
    <el-dialog :title="dialogTitle" :visible.sync="detailVisible" width="65%" top="3%" align="center">
      <modal-detail v-if="detailVisible" :rowData="detailData" :detailType="detailType" @close="detailVisible = false"/>
    </el-dialog>
  </div>
</template>

<script>
  import breadCrumb from '@/component/breadcrumb/breadcrumb.vue';
  import pageCut from '@/component/pagination/index.vue';
  import modalDetail from './components/modal-detail';

  export default {
    data() {
      return {
        datatime: '',
        showCover: false,
        detailVisible: false, // 查看详情
        detailData: {},
        dialogTitle: '',
        detailType: 0,
        tableData: [],
        breadList: [
          {
            name: '南繁备案'
          }
        ],
        type: {
          value: '',
          name: ''
        },
        direction: {
          value: '',
          name: ''
        },
        date: '',
        options_type: [
          {},
          {
            value: 4,
            name: '个人'
          },
          {
            value: 3,
            name: '企业'
          }, {
            value: 1,
            name: '科研单位'
          }, {
            value: 2,
            name: '高等院校'
          }, {
            value: 5,
            name: '其他'
          }
        ],
        options_direction: [
          {},
          { value: 1, name: '科研育种' },
          { value: 2, name: '种子生产' }
        ],

        id: '',
        disabled: false,
        disabled_direction: false
      };
    },
    components: {
      breadCrumb,
      pageCut,
      modalDetail
    },
    created() {
      this.getData();
    },
    methods: {
      addRecord() {
        this.showCover = true;
        this.$store.commit('addCache', 'nanfanbase');
        // this.submit()
      },
      async getData() {
        const api = this.$fetchApi.nanfanRegisterList.api;
        const data = await this.$fetchData.fetchGet({}, api, 'json');
        if (data.code === '200') {
          this.tableData = data.result || [];
        }
      },
      submit() {
        if (!this.type || !this.direction) return;
        this.beforeClose();
        // this.$store.commit("addCache", "nanfanbase");
        console.log(this.type, this.direction);
        console.log(this.datatime, 'this.datatime');
        this.$router.push({
          name: 'nanfanregisterindex',
          query: {
            id: this.id,
            type: JSON.stringify(this.type),
            direction: JSON.stringify(this.direction),
            date: this.datatime
          }
        });
      },
      beforeClose() {
        this.disabled = false;
        this.showCover = false;
        this.disabled_direction = false;
      },
      // 删除
      removeRegister(val, index) {
        this.$confirm('确认删除？')
          .then(async () => {
            const api = this.$fetchApi.nanfanRegisterRemove.api + val.id;
            const data = await this.$fetchData.fetchPost({}, api, 'json');
            if (data.code === '200') {
              this.$message({
                message: '删除成功',
                type: 'warning'
              });
            }
            this.tableData.splice(index, 1);
          })
          .catch(err => console.log(err));
      },
      handleEdit(index, data, status) {
        if (status == 1) {
          // 查看
          // this.$store.commit('removeCache', 'nanfanbase');
          this.dialogTitle = '备案详情';
          this.detailVisible = true;
          this.detailData = data;
          this.detailType = status;
        } else if (status == 3) {
          // 编辑
          const typeFun = {
            name: data.registerProperty,
            value: data.registerProperty == '科研单位' ? 1 : (data.registerProperty == '高等院校' ? 2 : (
              data.registerProperty == '企业' ? 3 : (data.registerProperty == '个人' ? 4 : 5)))
          };
          const directionFun = {
            name: data.nanfanDirection,
            value: data.nanfanDirection == '科研育种' ? 1 : 2
          };
         this.$router.push({
            name: 'nanfanregisterdetail',
            query: {
              id: data.id,
              type: JSON.stringify(typeFun),
              direction: JSON.stringify(directionFun),
              date: data.year
            }
          });
        }
      },
      handleDelete(index, row) {
        console.log(index, row);
      },
      pageChange(val) {
        console.log(val.page_index);
      }
    }
  };
</script>
<style lang="less" scoped>
  .nanfanregister {
    background: rgba(249, 251, 255, 0.99);
    min-height: 400px;

    &-content {
      box-sizing: border-box;
      padding: 0 20px;

      &-title {
        position: relative;
        height: 60px;
        line-height: 60px;
        text-indent: 20px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -4px;
          display: block;
          width: 8px;
          height: 8px;
          background: rgba(51, 128, 254, 1);
          border-radius: 50%;
        }
      }

      /deep/ .el-table td {
        border: none;
        padding: 2px 0;
        // margin: 5px 0;
        // background-color:#fff;
        // box-sizing: border-box;
      }

      &-btn {
        text-align: center;
        height: 150px;
        line-height: 50px;
      }

      &-item {
        width: 80%;
        margin: 10px auto;
        text-align: center;

        span {
          margin-right: 35px;
          font-size: 18px;
        }

        /deep/ .el-dropdown-link {
          cursor: pointer;
          color: #409eff;
        }

        /deep/ .el-icon-arrow-down {
          font-size: 16px;
        }
      }
    }
  }
</style>
